type Command = "sales" | "chat" | "assistant" | "chat_atoffice";

type CmsImage = {
  height: number;
  url: string;
  width: number;
};

export type CharacterImage = {
  normal: CmsImage;
  attention: CmsImage;
  happy: CmsImage;
  talk: CmsImage;
  respectful: CmsImage;
  greeting: CmsImage;
};

export type ClientResponseSchema = {
  id: string;
  clientCode: string;
  name: string;
  commands: { fieldId: string; command: Command }[];
  character: CharacterImage;
  facebook?: string;
  faqUrl?: string;
  inquiryUrl?: string;
  instagram?: string;
  line?: string;
  tiktok?: string;
  xTwitter?: string;
  themeColor?: string;
  excludedUrls: { fieldId: string; url: string }[];
  isSignage: boolean;
  isForExhibition: boolean;
  exhibitionBackground?: CmsImage;
  isAssistantOnly: boolean;
  smallIcon?: CmsImage;
  voiceCharacter?: string[];
  voiceCharacterVolume?: number[];
  voiceCharacterSpeed?: number[];
  voiceCharacterAlpha?: number[];
  voiceCharacterPitch?: number[];
  bottomHeightPC?: number;
  bottomHeight: number;
  assistantBanner?: CmsImage;
  bannerLink?: string;
  logoImage?: CmsImage;
  buttonTheme?: string;
  pitaliyLink?: string;
};

export type ClientSchema = {
  id: string;
  clientCode: string;
  name: string;
  character: {
    normal: string;
    attention: string;
    happy: string;
    talk: string;
    respectful: string;
    greeting: string;
  };
  facebook?: string;
  faqUrl?: string;
  inquiryUrl?: string;
  instagram?: string;
  line?: string;
  tiktok?: string;
  xTwitter?: string;
  themeColor?: string;
  commands: Command[];
  excludedUrls?: string[];
  voiceApiUrl?: string;
  isSignage: boolean;
  isForExhibition: boolean;
  exhibitionBackground?: string;
  isAssistantOnly: boolean;
  smallIcon: string;
  voiceCharacter?: string;
  voiceCharacterVolume?: number;
  voiceCharacterSpeed?: number;
  voiceCharacterAlpha?: number;
  voiceCharacterPitch?: number;
  bottomHeightPC?: number;
  bottomHeight: number;
  assistantBanner?: string;
  bannerLink?: string;
  logoImage?: string;
  buttonTheme?: string;
  pitaliyLink?: string;
};

export const clientResponseToSchema = (
  res: ClientResponseSchema,
): ClientSchema => ({
  ...res,
  character: {
    normal: res.character.normal.url,
    attention: res.character.attention.url,
    happy: res.character.happy.url,
    respectful: res.character.respectful.url,
    greeting: res.character.greeting.url,
    talk: res.character.talk.url,
  },
  commands: res.commands.map((c) => c.command),
  excludedUrls: res.excludedUrls?.map((e) => e.url) ?? [],
  isSignage: res.isSignage,
  isForExhibition: res.isForExhibition,
  exhibitionBackground: res.exhibitionBackground?.url ?? undefined,
  isAssistantOnly: res.isAssistantOnly,
  smallIcon: res.smallIcon?.url ?? "",
  voiceCharacter: res.voiceCharacter?.[0] ?? undefined,
  voiceCharacterAlpha: res.voiceCharacterAlpha?.[0] ?? 0,
  voiceCharacterPitch: res.voiceCharacterPitch?.[0] ?? 0,
  voiceCharacterSpeed: res.voiceCharacterSpeed?.[0] ?? 0,
  voiceCharacterVolume: res.voiceCharacterVolume?.[0] ?? 0,
  bottomHeightPC: res.bottomHeightPC ?? 0,
  bottomHeight: res.bottomHeight ?? 0,
  assistantBanner: res.assistantBanner?.url ?? undefined,
  bannerLink: res.bannerLink ?? undefined,
  logoImage: res.logoImage?.url ?? undefined,
  pitaliyLink: res.pitaliyLink ?? undefined,
});
