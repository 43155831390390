import { useAtomValue } from "jotai";
import { IoIosArrowForward } from "react-icons/io";

import s from "@/commands/AssistantOnly/selectButton.module.scss";
import {
  Button3DWrap,
  Button3D,
  ButtonRoundedCorners,
} from "@/commands/AssistantOnly/selectButton.style";
import { clientConfigAtom } from "@/utils/atoms";

type ChatOption = {
  label: string;
  value: string;
};

type Props = {
  option: ChatOption;
  onClick: () => void;
};

export function SelectButton({ option, onClick }: Props) {
  const client = useAtomValue(clientConfigAtom);

  let buttonContent;

  switch (client.buttonTheme?.[0]) {
    case "button_3d":
      buttonContent = (
        <Button3DWrap>
          <Button3D type="button" onClick={onClick} key={option.value}>
            <span>{option.label}</span>
          </Button3D>
        </Button3DWrap>
      );
      break;
    case "button_roundedCorners":
      buttonContent = (
        <ButtonRoundedCorners
          type="button"
          onClick={onClick}
          key={option.value}
        >
          <span>{option.label}</span>
        </ButtonRoundedCorners>
      );
      break;
    // default:
    //   buttonContent = (
    //     <ButtonFlat type="button" onClick={onClick} key={option.value}>
    //       <span>{option.label}</span>
    //     </ButtonFlat>
    //   );
    //   break;
    default:
      buttonContent = (
        <button
          className={s.SelectButton}
          type="button"
          onClick={onClick}
          key={option.value}
        >
          <span>{option.label}</span> <IoIosArrowForward />
        </button>
      );
      break;
  }

  return buttonContent;
}
