import { IoMdInformationCircleOutline } from "react-icons/io";
import styled from "styled-components";

import { ButtonModal } from "@/components/ButtonModal";
import CancelIcon from "@/public/cancel.svg";

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 358px;
  height: 281px;
  border-radius: 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.95);
`;

const CancelRoundButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
`;

const CancelRoundIcon = styled.img``;

const InfoIcon = styled(IoMdInformationCircleOutline)`
  width: 26.67px;
  height: 26.67px;

  gap: 0px;
  opacity: 0px;
  color: rgba(55, 112, 255, 1);
`;

const InfoText = styled.p`
  margin-top: 4px;
  color: rgba(28, 28, 28, 1);
  font-weight: 500;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 24px 0;

  .cancelButton {
    margin-right: 4px;
  }

  .resetButton {
    margin-left: 4px;
    background: rgba(55, 112, 255, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
  }
`;

type Props = {
  onCancel: () => void;
  onClick: () => void;
};

export function ResetConfirmModal({ onCancel, onClick }: Props): JSX.Element {
  return (
    <Overlay>
      <ModalWrapper>
        <CancelRoundButton onClick={onCancel}>
          <CancelRoundIcon src={CancelIcon} />
        </CancelRoundButton>
        <InfoIcon />
        <InfoText>
          会話を初めからやり直します。
          <br />
          よろしいですか？
        </InfoText>
        <ButtonWrapper>
          <ButtonModal
            className="cancelButton"
            buttonType="cancel"
            onClick={onCancel}
          />
          <ButtonModal
            className="resetButton"
            buttonType="reset"
            onClick={onClick}
          />
        </ButtonWrapper>
      </ModalWrapper>
    </Overlay>
  );
}
