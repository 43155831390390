import { useAtomValue, useSetAtom } from "jotai";
import { useState, useRef, useEffect, useCallback } from "react";

import {
  languageAtom,
  isCharacterSpeakingAtom,
  isVoiceInputAllowedAtom,
} from "@/utils/atoms";

type Props = {
  onStartRecording?: () => void;
  onStopRecording?: (message: string) => void;
  // レコグニションを検出検出中のイベントハンドラ
  onResult?: (message: string) => void;
};

type ReturnType = {
  startRecording: () => void;
  stopRecording: () => void;
  isMicRecording: boolean;
  getUserMediaPermission: () => void;
};

let recognition: SpeechRecognition;
if (typeof window !== "undefined") {
  const SpeechRecognition =
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.webkitSpeechRecognition || window.SpeechRecognition;
  recognition = new SpeechRecognition();
}

export const useVoiceInput = ({
  onStartRecording,
  onStopRecording,
  onResult,
}: Props): ReturnType => {
  const language = useAtomValue(languageAtom);
  const isCharacterSpeaking = useAtomValue(isCharacterSpeakingAtom);
  const setIsVoiceInputAllowed = useSetAtom(isVoiceInputAllowedAtom);

  const [isMicRecording, setIsMicRecording] = useState(false);
  const transcriptsRef = useRef<string[]>([]);
  // const [currentTranscript, setCurrentTranscript] = useState<string>("");

  useEffect(() => {
    recognition.lang = language; // 言語を指定する
    recognition.interimResults = true; // 途中経過を取得する
    recognition.continuous = true; // 連続的に音声認識を行う
    recognition.maxAlternatives = 1; // 1つの認識結果のみを取得する

    const startHandle = () => {
      if (onStartRecording != null) {
        onStartRecording();
      }
      setIsMicRecording(true);
      transcriptsRef.current = [];
    };
    recognition.addEventListener("start", startHandle);

    const errorHandle = () => {};
    recognition.addEventListener("error", errorHandle);

    const endHandle = () => {
      if (onStopRecording != null) {
        onStopRecording(transcriptsRef.current.join(" "));
      }
      setIsMicRecording(false);
      transcriptsRef.current = [];
    };
    recognition.addEventListener("end", endHandle);

    const resultHandle = (event: SpeechRecognitionEvent) => {
      const { results } = event;
      const result = results[results.length - 1];
      const { transcript } = result[0];

      if (result.isFinal) {
        transcriptsRef.current.push(transcript);
      }
      if (onResult != null) {
        onResult(transcript);
      }
    };
    recognition.addEventListener("result", resultHandle);

    return () => {
      recognition.removeEventListener("start", startHandle);
      recognition.removeEventListener("error", errorHandle);
      recognition.removeEventListener("end", endHandle);
      recognition.removeEventListener("result", resultHandle);
    };
  }, [language, onResult, onStartRecording, onStopRecording]);

  const getUserMediaPermission = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsVoiceInputAllowed(true);
    } catch (error) {
      // Errorがないと try/catchが成り立たなくなるので残しておきます
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [setIsVoiceInputAllowed]);

  const startRecording = useCallback(() => {
    // キャラクターが発話中の場合は、マイクを起動しない
    if (isCharacterSpeaking || isMicRecording) {
      return;
    }
    recognition.start();
  }, [isCharacterSpeaking, isMicRecording]);

  const stopRecording = useCallback(() => {
    recognition.stop();
  }, []);

  return {
    startRecording,
    stopRecording,
    isMicRecording,
    getUserMediaPermission,
  };
};
